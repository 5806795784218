
.container {
  padding: 0 15px;
  margin: 0 auto;
  min-width: 335px;
}

@media (min-width: 375px) {
  .container {
    width: 365px;
  }
}

@media (min-width: 768px) {
  .container {
    width: 734px;
  }
}


@media (min-width: 1440px) {
  .container {
    width: 1278px;
  }
}
