@import '~normalize.css';
@import './variable.css';

html {
  box-sizing: border-box;
  width: 100vw;
  overflow-x: hidden;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  font-size: 16px;
  color: var(--white);
  background-color: var(--black-color);
}

img {
  display: block;
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

p:last-child {
  margin-bottom: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  color: currentColor;
  text-decoration: none;
}

button {
  cursor: pointer;
}

.body-scroll-lock {
  height: 100vh;
  overflow-y: hidden;
}
