.polygon_card {
  position: absolute;
  display: flex;
  top: 560px;
  left: 121px;
  width: 146px;
  height: 66px;
  background-color: var(--card-info-color);
  border-radius: var(--border-radius);
  padding: 14px 18px;
}

.polygon_icon {
  width: 30px;
  height: 30px;
  background-color: var(--orange-1);
  border-radius: 50%;
  padding: 8px;
  margin-right: 8px;
}

.box_title {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.polygon_title {
  font-weight: 700;
  line-height: 1.13;
}

.polygon_text {
  color: var(--color-info-tex);
  font-size: 12px;
  line-height: 1.34;
}

.cal_card {
  position: absolute;
  top: 696px;
  left: 236px;
  width: 119px;
  height: 76px;
  padding: 14px 18px;
  background-color: var(--orange-1);
  border-radius: var(--border-radius);
  margin-bottom: 4px;
}

.calIcon {
  width: 20px;
  height: 20px;
  background-color: #efa082;
  border-radius: 50%;
  padding: 4px;
  fill: white;
}

.cal_summa {
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: -1px;
  text-transform: uppercase;
}

.smallText {
  color: var(--color-info-tex);
  font-size: 12px;
  font-weight: 700;
  line-height: 1.34;
}

@media screen and (min-width: 768px) {
  .polygon_card {
    top: 714px;
    left: 331px;
    width: 206px;
    height: 96px;
    padding: 28px 20px;
  }

  .polygon_icon {
    width: 40px;
    height: 40px;
    padding: 10px;
  }

  .box_title {
    gap: 8px;
  }
  .polygon_title {
    font-size: 24px;
    line-height: 1;
  }
  .polygon_text {
    font-size: 16px;
    line-height: 1.5;
  }

  /*  */
  .cal_card {
    top: 866px;
    left: 556px;
    width: 180px;
    height: 110px;
    padding: 14px 28px;
  }
  .calIcon {
    width: 24px;
    height: 24px;
    margin-bottom: 8px;
  }

  .cal_summa {
    font-size: 48px;
    line-height: 1.04;
  }

  .smallText {
    color: var(--color-info-tex);
    font-size: 16px;
    line-height: 1.5;
  }
}

@media screen and (min-width: 1440px) {
  .polygon_card {
    top: 384px;
    left: 770px;
  }
  .cal_card {
    top: 519px;
    left: 1228px;
  }
}
