.logo_wrap {
  display: flex;
  gap: 8px;

}
.logo_wrap_not{
    padding-top: 24px;
}
.logo {
  width: 44px;
  height: 17px;
  stroke: var(--orange);
  fill: var(--orange);
}


.logo_white {
  stroke: var(--white);
  fill: var(--white);
}
.logo_name {
  width: 100px;
  height: 16px;
}

.svg_logout {
  width: 20px;
  height: 20px;
}

@media screen and (max-width: 768px) {
  .logo {
    width: 36px;
    height: 13px;
  }
  .logo_name {
    width: 82px;
    height: 12px;
  }
}

@media screen and (min-width: 768px) {
  .logo_wrap_not{
    padding-top: 32px;
  }
}
