:root {
  --black-color: #040404;
  --card-info-color: #303030;
  --orange: #e6533c;
  --orange-1: #ef8964;
  --beige: #efa082;
  --white: #efede8;
  --semiwhite: rgba(239, 237, 232, 0.3);
  --header-white-color: #f6f6f6;
  --error: #d80027;
  --success: #3cbf61;

  --border-radius: 12px;
  --border-input: 1px solid rgba(239, 237, 232, 0.3);
  --border-accent: 1px solid #e6533c;
  --border-success: 1px solid #3cbf61;
  --border-error: 1px solid #d80027;
  --border-solid-color: 1px solid rgba(239, 237, 232, 0.2);
  --background-avatar: rgba(48, 48, 48, 0.3);
  --border-section-header: 1px solid rgba(239, 237, 232, 0.20);
  --color-no-activ: rgba(239, 237, 232, 0.40);
  --color-info-tex: rgba(239, 237, 232, 0.65);
  --background-linear-gradient-mob: linear-gradient(170deg, #040404 3.66%, rgba(4, 4, 4, 0.00) 19.15%), linear-gradient(73deg, #040404 6.11%, rgba(4, 4, 4, 0.00) 66.68%);
  --background-linear-gradient: linear-gradient(
      168deg,
      #040404 14.75%,
      rgba(4, 4, 4, 0) 52.97%
    ),
    linear-gradient(73deg, #040404 6.11%, rgba(4, 4, 4, 0) 66.68%);
  --desk-background-linear-gradient: linear-gradient(
    80deg,
    #040404 -2.45%,
    rgba(4, 4, 4, 0) 68.17%
  );
  --background-linear-gradient-products: linear-gradient(
    89deg,
    #040404 1.1%,
    rgba(4, 4, 4, 0) 70.79%
  );
  --background-linear-auth-mb:linear-gradient(168deg, #040404 14.75%, rgba(4, 4, 4, 0.00) 52.97%), linear-gradient(73deg, #040404 6.11%, rgba(4, 4, 4, 0.00) 66.68%);
  --background-product: linear-gradient(89deg, #040404 1.1%, rgba(4, 4, 4, 0.00) 70.79%);
  
}


